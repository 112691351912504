<template>
  <div class="w-full px-4 pt-16 flex flex-col">
    <div class="w-full flex flex-col items-center">
      <img
        src="@/assets/student-icon.svg"
        alt="Student Icon"
        class="w-12 h-12 rounded-full mb-2"
      />
      <p class="font-medium">{{ name }}</p>
      <p class="text-xs text-gray-400">{{ studentId }}</p>
    </div>
    <div class="w-full mt-8 mb-6 flex flex-col">
      <p class="mb-2 font-medium">通知</p>
      <div class="flex flex-col rounded-md bg-white">
        <m-s-info-item text="開室通知" icon="calender" path="/notification" />
      </div>
    </div>
    <div v-if="allowStudentToChangePassword" class="w-full mb-6 flex flex-col">
      <p class="mb-2 font-medium">ログイン設定</p>
      <div class="flex flex-col rounded-md bg-white">
        <m-s-info-item
          text="パスワードの変更"
          icon="setting"
          path="/reset_password/mingaku"
        />
      </div>
    </div>
    <div class="w-full flex flex-col">
      <p class="mb-2 font-medium">サポート</p>
      <div class="flex flex-col rounded-md bg-white">
        <m-s-info-item
          text="開室カレンダー"
          icon="calender"
          path="https://mingaku.net/guide/calendar"
        />
        <div class="w-full bg-primary-100" style="height: 1px" />
        <m-s-info-item
          text="公式ガイド"
          icon="info"
          path="https://mingaku.net/guide-student"
        />
        <m-s-info-item
          text="QANDA"
          icon="external"
          path="https://mingaku.net/guide/qanda"
        />
        <m-s-info-item
          text="プライバシーポリシー"
          icon="external"
          path="https://plaid-scallop-d50.notion.site/67b190f270a142c28294185e2516ed48"
        />
      </div>
    </div>
    <div
      v-if="!isInMingakuStudentApp"
      class="w-full py-3 mt-8 mb-6 flex justify-center rounded-md border-2 border-red-500 bg-white cursor-pointer transition-all duration-300 hover:bg-gray-100"
      @click="signOut"
    >
      <p class="text-red-500">ログアウト</p>
    </div>
    <div v-else class="w-full flex flex-col items-center mt-8 mb-8">
      <p class="text-xs text-gray-400">© 2021 Mingaku</p>
    </div>
  </div>
</template>

<script lang="ts">
import { signOut } from "@/api/auth";
import MSInfoItem from "@/components/setting/MSInfoItem.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MSInfoItem
  }
})
export default class Setting extends Vue {
  get name(): string {
    if (!store.state.student) {
      return "-";
    }
    return store.state.student.data.name;
  }

  get studentId(): string {
    if (!store.state.role) {
      return "";
    }
    return store.state.role.data.loginId ?? "";
  }

  get isInMingakuStudentApp(): boolean {
    return store.state.isInMingakuStudentApp;
  }

  get allowStudentToChangePassword(): boolean {
    return (
      store.state.schoolConfig?.data.allowStudentsToChangePassword ?? false
    );
  }

  async signOut() {
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "ログアウト中...");
    try {
      await signOut();
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      alert(
        "ログアウトに失敗しました。時間を置いてから再度実行してみてください"
      );
      return;
    }
    store.commit("SIGN_OUT");
    this.$router.replace("/login");
  }
}
</script>
